import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Dropdown } from '../dropdown/Dropdown';
import { MenuItem } from '../menu/MenuItem';
import { usePagination } from '../private/hooks/usePagination';
import { PaginationItem } from './PaginationItem';
import { getItemAriaLabel } from '../utils';

const PaginationCompact = React.forwardRef((props, ref) => {

  const {
    count = 1,
    defaultPage = 1,
    disabled = false,
    edge,
    fullWidth = false,
    hideNextButton = false,
    hidePrevButton = false,
    onChange,
    page,
    renderItem = (item) => <PaginationItem {...item} />,
    showFirstButton = false,
    showLastButton = false,
    ...other
  } = props;

  const { items } = usePagination({
    boundaryCount: Math.ceil(count / 2),
    count,
    defaultPage,
    disabled,
    hideNextButton,
    hidePrevButton,
    onChange,
    showFirstButton,
    showLastButton,
    siblingCount: 0,
    ...props
  });

  let first;
  let next;
  let previous;
  let last;

  const pageItems = items.flatMap((item) => {
    if (item.type === 'first') {
      first = item;
      return [];
    }
    if (item.type === 'previous') {
      previous = item;
      return [];
    }
    if (item.type === 'next') {
      next = item;
      return [];
    }
    if (item.type === 'last') {
      last = item;
      return [];
    }
    return item;
  });

  const ulClasses = classNames('sui-flex sui-flex-wrap sui-items-center sui-m-0 sui-p-0');

  const liClasses = classNames({
    'first:sui-mr-auto': fullWidth && !hidePrevButton && !showFirstButton,
    'last:sui-ml-auto': fullWidth && !hideNextButton && !showLastButton,
    '[&:nth-child(2)]:sui-mr-auto': fullWidth && showFirstButton,
    '[&:nth-last-child(2)]:sui-ml-auto': fullWidth && showLastButton
  });

  return (
    <nav
      className={fullWidth ? 'sui-w-full' : undefined}
      aria-label="Pagination Navigation"
      ref={ref}
      {...other}
    >
      <ul className={ulClasses}>
        {first ? (
          <li className={liClasses}>
            {renderItem({
              ...first,
              edge,
              'aria-label': getItemAriaLabel(first.type, first.page, first.selected)
            })}
          </li>
        ) : null}
        {previous ? (
          <li className={liClasses}>
            {renderItem({
              ...previous,
              edge,
              'aria-label': getItemAriaLabel(previous.type, previous.page, previous.selected)
            })}
          </li>
        ) : null}
        <li>
          <div className="sui-ml-2 sui-mr-1 sui-min-w-[76px]">
            <Dropdown
              value={page}
              onChange={onChange}
            >
              {pageItems.map((item, index) => (
                <MenuItem key={index} value={item.page}>{item.page}</MenuItem>
              ))}
            </Dropdown>
          </div>
        </li>
        <li>
          <p className="sui-ml-1 sui-mr-2 sui-font-regular sui-text-primary sui-text-base sui-tracking-normal sui-normal-case sui-line-clamp-unset">
            of {pageItems.length}
          </p>
        </li>
        {next ? (
          <li className={liClasses}>
            {renderItem({
              ...next,
              edge,
              'aria-label': getItemAriaLabel(next.type, next.page, next.selected)
            })}
          </li>
        ) : null}
        {last ? (
          <li className={liClasses}>
            {renderItem({
              ...last,
              edge,
              'aria-label': getItemAriaLabel(last.type, last.page, last.selected)
            })}
          </li>
        ) : null}
      </ul>
    </nav>
  );
});

PaginationCompact.displayName = 'PaginationCompact';

PaginationCompact.propTypes = {
  /**
   * The total number of pages.
   * @default 1
   */
  count: PropTypes.number,
  /**
   * The page selected by default when the component is uncontrolled.
   * @default 1
   */
  defaultPage: PropTypes.number,
  /**
   * If `true`, the component is disabled.
   * @default false
   */
  disabled: PropTypes.bool,
  /**
   * If given, uses a negative margin to counteract the padding on the buttons close to the edge.
   */
  edge: PropTypes.object,
  /**
   * If `true`, the button will take up the full width of its container.
   * @default false
   */
  fullWidth: PropTypes.bool,
  /**
   * If `true`, hide the next-page button.
   * @default false
   */
  hideNextButton: PropTypes.bool,
  /**
   * If `true`, hide the previous-page button.
   * @default false
   */
  hidePrevButton: PropTypes.bool,
  /**
   * Callback fired when the page is changed.
   */
  onChange: PropTypes.func,
  /**
   * The current page.
   */
  page: PropTypes.number,
  /**
   * The PaginationItems rendered
   * @default (item) => <PaginationItem {...item} />
   */
  renderItem: PropTypes.func,
  /**
   * If `true`, show the first-page button.
   * @default false
   */
  showFirstButton: PropTypes.bool,
  /**
   * If `true`, show the last-page button.
   * @default false
   */
  showLastButton: PropTypes.bool
};

export { PaginationCompact };
