import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { usePagination } from '../private/hooks/usePagination';
import { PaginationItem } from './PaginationItem';
import { getItemAriaLabel } from '../utils';

export const Pagination = React.forwardRef((props, ref) => {

  const {
    boundaryCount = 1,
    count = 1,
    defaultPage = 1,
    disabled = false,
    edge,
    fullWidth = false,
    hideNextButton = false,
    hidePrevButton = false,
    onChange,
    page,
    renderItem = (item) => <PaginationItem {...item} />,
    showFirstButton = false,
    showLastButton = false,
    siblingCount = 1,
    ...other
  } = props;

  const { items } = usePagination({ ...props });

  const ulClasses = classNames('sui-flex sui-flex-wrap sui-items-center sui-m-0 sui-p-0');

  return (
    <nav
      className={fullWidth ? 'sui-w-full' : undefined}
      role="navigation"
      aria-label="Pagination Navigation"
      ref={ref}
      {...other}
    >
      <ul className={ulClasses} role="listbox">
        {items.map((item, index) => (
          <li
            key={index}
            className={classNames({
              'first:sui-mr-auto': fullWidth && !hidePrevButton && !showFirstButton,
              'last:sui-ml-auto': fullWidth && !hideNextButton && !showLastButton,
              '[&:nth-child(2)]:sui-mr-auto': fullWidth && showFirstButton,
              '[&:nth-last-child(2)]:sui-ml-auto': fullWidth && showLastButton
            })}
          >
            {renderItem({
              ...item,
              edge,
              role: 'option',
              'aria-label': getItemAriaLabel(item.type, item.page, item.selected)
            })}
          </li>
        ))}
      </ul>
    </nav>
  );
});

Pagination.displayName = 'Pagination';

Pagination.propTypes = {
  /**
   * Number of always visible pages at the beginning and end.
   * @default 1
   */
  boundaryCount: PropTypes.number,
  /**
   * The total number of pages.
   * @default 1
   */
  count: PropTypes.number,
  /**
   * The page selected by default when the component is uncontrolled.
   * @default 1
   */
  defaultPage: PropTypes.number,
  /**
   * If `true`, the component is disabled.
   * @default false
   */
  disabled: PropTypes.bool,
  /**
   * If given, uses a negative margin to counteract the padding on the buttons close to the edge.
   */
  edge: PropTypes.object,
  /**
   * If `true`, the button will take up the full width of its container.
   * @default false
   */
  fullWidth: PropTypes.bool,
  /**
   * If `true`, hide the next-page button.
   * @default false
   */
  hideNextButton: PropTypes.bool,
  /**
   * If `true`, hide the previous-page button.
   * @default false
   */
  hidePrevButton: PropTypes.bool,
  /**
   * Callback fired when the page is changed.
   */
  onChange: PropTypes.func,
  /**
   * The current page.
   */
  page: PropTypes.number,
  /**
   * The PaginationItems rendered
   * @default (item) => <PaginationItem {...item} />
   */
  renderItem: PropTypes.func,
  /**
   * If `true`, show the first-page button.
   * @default false
   */
  showFirstButton: PropTypes.bool,
  /**
   * If `true`, show the last-page button.
   * @default false
   */
  showLastButton: PropTypes.bool,
  /**
   * Number of always visible pages before and after the current page.
   * @default 1
   */
  siblingCount: PropTypes.number
};