import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconButton } from '../button/IconButton';
import { ButtonBase } from '../private/components/button/ButtonBase';
import { ArrowBack } from '../private/icons/ArrowBack';
import { ArrowForward } from '../private/icons/ArrowForward';
import { More } from '../private/icons/More';
import { DoubleArrowBack } from '../private/icons/DoubleArrowBack';
import { DoubleArrowForward } from '../private/icons/DoubleArrowForward';

const edgeMapping = {
  first: 'start',
  previous: 'start',
  next: 'end',
  last: 'end'
};

export const PaginationItem = React.forwardRef((props, ref) => {

  const {
    component,
    edge = {},
    components = {},
    disabled = false,
    href,
    page,
    selected = false,
    slots = {},
    type = 'page',
    ...other
  } = props;

  const normalizedIcons = {
    previous: components.previous || ArrowBack,
    next: components.next || ArrowForward,
    first: components.first || DoubleArrowBack,
    last: components.last || DoubleArrowForward,
  };

  const Icon = normalizedIcons[type];

  if (type === 'start-ellipsis' || type === 'end-ellipsis') {
    return (
      <div
        ref={ref}
        className="sui-inline-flex sui-items-center sui-justify-center sui-w-8 sui-h-8 sui-mx-1"
      >
        <More color="subtle" size="small" />
      </div>
    );
  }

  return type === 'page' ? (
    <ButtonBase
      ref={ref}
      disabled={disabled}
      aria-selected={selected === false ? false : null}
      className={classNames('sui-text-base sui-leading-tight sui-tracking-normal sui-px-1 sui-mx-1 sui-h-8 hover:sui-bg-subtle hover:sui-text-primary focus-visible:sui-bg-focus focus-visible:sui-text-primary', {
        'sui-font-regular sui-text-subtle': !selected,
        'sui-font-bold sui-text-primary sui-border-solid sui-border-b-2 sui-border-accent': selected
      })}
      href={href}
      style={{ minWidth: '32px' }}
      {...other}
    >
      {page}
    </ButtonBase>
  ) : (
    <IconButton
      ref={ref}
      disabled={disabled}
      icon={Icon}
      edge={edge[type] && edgeMapping[type]}
      iconSize={(type === 'previous' || type === 'next') ? 'regular' : 'small'}
      href={href}
      buttonSize="relaxed"
      {...other}
    />
  );
});

PaginationItem.displayName = 'PaginationItem';

PaginationItem.propTypes = {
  /**
   * @ignore
   */
  children: PropTypes.node,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: PropTypes.elementType,
  /**
   * The components used for each slot inside.
   * @default {}
   */
  components: PropTypes.shape({
    first: PropTypes.elementType,
    last: PropTypes.elementType,
    next: PropTypes.elementType,
    previous: PropTypes.elementType,
  }),
  /**
   * If `true`, the component is disabled.
   * @default false
   */
  disabled: PropTypes.bool,
  /**
   * If given, uses a negative margin to counteract the padding on the buttons close to the edge.
   */
  edge: PropTypes.object,
  /**
   * The href used for the ButtonBase
   */
  href: PropTypes.string,
  /**
   * The current page number.
   */
  page: PropTypes.node,
  /**
   * If `true` the pagination item is selected.
   * @default false
   */
  selected: PropTypes.bool,
  slots: PropTypes.object,
  /**
   * The type of pagination item.
   * @default 'page'
   */
  type: PropTypes.oneOf([
    'end-ellipsis',
    'first',
    'last',
    'next',
    'page',
    'previous',
    'start-ellipsis',
  ])
};